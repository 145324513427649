import React, { useState } from 'react'
import { Layout, Content } from '../components'
import '../assets/type/fonts.css'
import '../assets/styles/better-together.scss'

const GirlfriendCircles = () => {
  return (
    <Layout>
      <Content>
        <header id="better-together-header">
          <div className="row">
            <div className="col text-center">
              <h2>Better Together</h2>
              <h4 className="subtitle">Creating Real Connections, Friendship, and Support in the Modern World</h4>
              <h4>&#10086;</h4>
              <img src="/img/tricia-profile-pic.jpg" alt="Tricia Andor" />
              <p>Tricia Andor</p>
            </div>
          </div>
        </header>

        <section id="better-together-content">
          <div className="row">
              <div className="large-12 columns">
                  <p className="lead"><strong><em>Fantastic!</em></strong> You made it to the inside!</p>
                  <p>I’m so glad you're here!</p>
                  <p>These days, so many of us could use a little more connection, friendship, and support in our lives.</p>
                  <p>Myself included!</p>
                  <p>This guide will show you what I did to <strong>create more connection</strong> in one week, and <strong>be more supportive</strong> to friends and family.</p>
                  <p>Here you go &mdash; Use any of these ideas that strike your fancy!</p>
                  <hr/>
                  <h4><span>#1</span> Reach out to friends with whom you’ve been out of touch.</h4>
                  <figure className="image small">
                    <img src="/img/trainings/better-together/1_Sue_1a.png" alt="Image of a text to my friend Sue" />
                    <figcaption>A text to my friend, Sue.</figcaption>
                  </figure>
                  <p>Though I wanted to know about her whole life, I kept the question / request finite to make it easy for her to respond.</p>
                  <p>Asking "how's your life" after you haven’t been in touch  is a big, broad question. Makes your friend have to think too much. It's like giving her or him homework. Keeping your question concrete and focused, to me, is a great way to respect the person's time, make responding easy (instead of a burden), and increase your chances of getting a response.</p>
                  <figure className="image xsmall">
                    <img src="/img/trainings/better-together/1_Sue_2.png" alt="Image of a text to my friend Sue" /> 
                    <figcaption>She appreciated that it was easy to respond!</figcaption>
                  </figure>
                  <p>Though most people will probably appreciate you making an easy entry point, they probably won’t specifically comment about it. Sue just happens to be keenly perceptive and aware of conversational dynamics, and voices her observations.</p>
                  <p>We texted back and forth this afternoon. It was really fun! These days, we’re mostly in touch when the big events happen in our social circle &mdash; engagements, weddings, funerals . So it felt great to just chat with no major event prompt.</p>
                  <p>I’ll give her a call sometime in the near future. Today it was just nice to touch base in the middle of the day.</p>
                  <figure className="image small">
                    <img src="/img/trainings/better-together/1_Kate_3.jpg" alt="a postcard to my friend Kate" />
                    <figcaption>Postcard to my friend Kate.</figcaption>
                  </figure>
                  <p>Kate had recently been accepted into a teaching certificate program. She's brilliant, and already has a master's in English, but made a big decision about getting certified to teach at the middle school and high school level.</p>
                  <figure className="image">
                    <img src="/img/trainings/better-together/1_Kate_4.jpg" alt="a postcard to my friend Kate" />
                  </figure>
                  <p>I'd been thinking a lot about her getting the certificate, and smiled every time I thought about it. So I jotted her a "good for you" postcard.</p>

                  <h4><span>#2</span> Create more connections with family.</h4>
                  <figure className="image xsmall">
                    <img src="/img/trainings/better-together/2_Pam_1.jpg" alt="A text from my aunt" />
                    <figcaption>A text from my aunt.</figcaption>
                  </figure>
                  <p>I got in closer texting-touch with a couple of my aunts in the past year. They’re great people and super-supportive, like this text shows. Checking in on me after I’d been sick for 5 weeks.</p>
                  <figure className="image xsmall">
                    <img src="/img/trainings/better-together/2_Sandy_3.jpg" alt="A text from a different aunt" />
                    <figcaption>A text from a different aunt.</figcaption>
                  </figure>
                  <p>Normally, after having a great time hanging out with someone, I’d think to myself, “That was so fun! She’s great!” This time, I let her know.</p>
                  <figure className="image small">
                    <img src="/img/trainings/better-together/2_Loma_4.jpg" alt="A letter from yet another aunt" />
                    <figcaption>A letter from yet another aunt!</figcaption>
                  </figure>
                  <p>I was making pumpkin molasses muffins while wearing an apron another aunt had given me decades ago. I jotted her a note to let her know what a great memory that was to me &mdash; when she’d given all the women in the family aprons when we were getting food prepared at her house. :)</p>
                  <p>This was her response. A real, live hand-written letter!</p>

                  <h4><span>#3</span> Make brand new friends.</h4>
                  <figure className="image">
                    <img src="/img/trainings/better-together/3_GFC_1.jpg" alt="The first GirlFriendCircles meetup group I hosted" />
                    <figcaption>The first GirlFriendCircles meetup group I hosted.</figcaption>
                  </figure>
                  <p>We just recently moved to Colorado, so making new local friends has been a major priority. This group was great fun, and seem like good potential for connection with others who are interesting and want more connection and community in their lives too.</p>
                  <div className="row">
                      <div className="medium-8 medium-centered columns">
                          <div className="video">
                              <div className="flex-video widescreen">
                                  <iframe src="https://player.vimeo.com/video/182653157" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                              </div>
                          </div>
                      </div>
                  </div>
                  <p>Me, talking about the importance of just consistently showing up. Even though that’s often difficult for me to do.</p>

                  <h4><span>#4</span> Develop a deeper relationships with someone you already know and trust.</h4>
                  <p>The other day, I realized I wanted to talk through my thoughts and feelings about something I hadn’t talked about with anyone besides my husband. It was a deeper topic, and, though I wanted to talk about it, I felt nervous about how the person might respond. I didn’t want advice or to be told what to do. I just wanted someone to listen and give support.</p>
                  <p>So I reached out to a good friend who did exactly that.</p>
                  <p>Even though she was a good friend, it still took courage.</p>

                  <h4><span>#5</span> Increase shared interest friendships.</h4>
                  <figure className="image">
                    <img src="/img/trainings/better-together/5_local-blogger_2.jpg" alt="An email to a local entrepreneur" />
                    <figcaption>An email to a local entrepreneur.</figcaption>
                  </figure>
                  <p>I got in touch with a local entrepreneur to let her know what I like about her website and message. I’m hoping she’ll be open to getting together for lunch or coffee sometime!</p>
                  <figure className="image">
                    <img src="/img/trainings/better-together/5_Writers_Group_1.jpg" alt="A writer’s group I joined several years ago" />
                    <figcaption>A writer’s group I joined several years ago.</figcaption>
                  </figure>
                  <p>The group was extremely daunting to join -- not because of the people, they were great -- but because I didn’t consider myself a writer. I’m SO glad I hung in! The group was incredible at giving support, feedback, and helping me start to see myself as a writer.</p>
                  <p>And I loved giving support and feedback about their writing as well.</p>
                  <p>That's part of the beauty of shared interest friendships. You're focused on similar things, and often know how to support one another since you are in the same boat.</p>

                  <h4><span>#6</span> Increase shared life-stage friendships.</h4>
                  <figure className="image">
                    <img src="/img/trainings/better-together/6_Aletha_walk_1.jpg" alt="Cows and a horse I saw while on a walk with a new friend." />
                    <figcaption>Cows and a horse I saw while on a walk with a new friend.</figcaption>
                  </figure>
                  <p>A gal from one of my meetup groups got reached out to see if I wanted to get together. We went for a trail walk. She and I are both married, with no children.</p>
                  <p>We pondered whether the saying that "cows sleep standing up" was false, true for only certain breeds, or used to be true but no longer is.</p>

                  <h4><span>#7</span> Initiate deeper and wider relationships with others. Say yes to courage, give goodness, receive goodness, serve, listen to your gut, forgive, and resolve conflict as kindly as you can.</h4>
                  <figure className="image">
                    <img src="/img/trainings/better-together/8_self_4.jpg" alt="courage" />
                  </figure>
                  <p>Courage is the number one thing I'm saying "yes" to as I seek more connection!</p>
                  <p>It takes courage for me to initiate, step up, and especially to host groups. I have this old belief that says if I host an event, no one's going to show up and that that will mean I'm not likable.</p>
                  <p>For me, the first step of courage begins with resolve and / or  excitement about hosting or inviting. Courage ends with relaxing into the creative process. Being myself during the activity, having fun, and being okay with whatever happens.</p>
                  <hr/>
                  <p>How 'bout you? What's one thing you would have to say "yes" to in order to deepen or expand your connections? </p>
                  <p className="lead">
                  I'd love to know! Write me at tricia@selfdevlab.com &mdash; in the subject line write "response to Better Together gift". I look forward to hearing from you!
                  </p>
              </div>
          </div>
        </section>
      </Content>
    </Layout>
  )
}

export default GirlfriendCircles
